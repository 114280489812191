import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LocationPage from './Locationpage';
import LocationList from './Locationlist';

function App() {
  return (
    <Router>
      <Routes>
        {/* Default route to show the list of available locations */}
        <Route path="/" element={<LocationList />} />
        
        {/* Route for specific location pages */}
        <Route path="/:locationId" element={<LocationPage />} />
      </Routes>
    </Router>
  );
}

export default App;
