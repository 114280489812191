import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import supabase from "./supabaseClient";
import "./LocationPage.css";

const LocationPage = () => {
  const { locationId } = useParams();
  const [locationData, setLocationData] = useState(null);
  const [language, setLanguage] = useState("English");

  useEffect(() => {
    const fetchLocation = async () => {
      const { data, error } = await supabase
        .from("Qr")
        .select("*")
        .eq("locationid", locationId)
        .single();

      if (error) {
        console.error(error);
      } else {
        setLocationData(data);
      }
    };

    fetchLocation();
  }, [locationId]);

  const handleLanguageToggle = () => {
    setLanguage(language === "English" ? "Spanish" : "English");
  };

  if (!locationData) {
    return <div>{language === "English" ? "No location data found" : "Datos de ubicación no encontrados"}</div>;
  }

  return (
    <section className="h-screen flex flex-col justify-between max-h-screen overflow-hidden w-screen">
      <header className="w-full p-8 text-xl font-bold bg-[#B8C8E1] flex items-center justify-between">
        <div className="w-80 md:flex hidden whitespace-nowrap">{language === "English" ? "Email: contact@clinicsanmiguel.com" : "Email: contact@clinicsanmiguel.com"}</div>
        <h1 className="text-center w-full">{locationData.locationtitle}</h1>
        <div className="md:flex hidden gap-3 items-center">
          <div>{language === "English" ? "Language:" : "Idioma:"}</div>
          <button
            className={`px-10 py-2 text-sm whitespace-nowrap rounded-md ${language === "English" ? "bg-[#2D3C59] text-white" : "bg-white text-[#2D3C59]"}`}

            onClick={handleLanguageToggle}
          >
            {language === "English" ? "Switch to Spanish" : "Cambiar a Inglés"}
          </button>
        </div>
      </header>
      <div className="flex flex-col px-8 gap-5 w-full items-center justify-center">
        <div className="text-6xl font-bold text-[#0F4698]">{language === "English" ? "Check In" : "Registrarse"}</div>
        <div className="flex md:flex-row items-center gap-14 flex-col-reverse">
          <p className="lg:text-5xl md:text-4xl text-3xl max-w-[700px] font-medium text-center">
            {language === "English"
              ? "Scan the QR code with your phone camera to check in and register in our POS system. It's that easy!"
              : "Escanee el código QR con la cámara de su teléfono para registrarse en nuestro sistema POS. ¡Es así de fácil!"}
          </p>
          {locationData.qrcode ? (
            <img
              width={400}
              height={400}
              src={locationData.qrcode}
              alt={`QR code for ${locationData.locationtitle}`}
            />
          ) : (
            <div>{language === "English" ? "No QR code found for this location." : "No se encontró un código QR para esta ubicación."}</div>
          )}
        </div>
      </div>
      <footer className="py-2 w-full bg-[#11252C] text-center text-white text-2xl font-medium">
        {language === "English" ? "Powered by: myclinicmd" : "Desarrollado por: myclinicmd"}
      </footer>
    </section>
  );
};

export default LocationPage;
