import React, { useEffect, useState } from "react";
import supabase from "./supabaseClient";

const LocationList = () => {
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLocations = async () => {
      const { data, error } = await supabase
        .from("Qr")
        .select("locationid, locationtitle, qrcode");

      if (error) {
        setError("Failed to load locations.");
        console.error(error);
      } else {
        setLocations(data);
      }
    };

    fetchLocations();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (locations.length === 0) {
    return <div>No locations available.</div>;
  }

  return (
    <div className="location-list">
        <h2>To view QR screen for specific location type its location id in url after / eg: qr.myclinicmd.com/ID</h2>
      <h2>Available Locations</h2>
      <ul>
        {locations.map((location) => (
          <li key={location.locationid}>
            <strong>ID:</strong> {location.locationid} | <strong>Title:</strong> {location.locationtitle}
            {location.qrcode ? (
              ""
            ) : (
              <span style={{ color: "red" }}> (QR-Code not available)</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LocationList;
